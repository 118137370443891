import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { expand, last, map, mergeMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Entity } from '../entities/entities.model';

import { AppSection, DynamicColumns, GlobalSettings } from '../global/global.model';
import { GlobalQuery } from '../global/global.query';
import {
	CalendarSettings,
	Column,
	ColumnCollection,
	DynamicColumn,
	TableActionRowSettings,
	TableCollection,
	TableItem,
	TableRow,
} from '../table/table.model';
import { TableStore } from './table.store';
import { getPlanStatusColor } from '../entities/plan/plan.model';
import { FilterParameters } from '../entities/filter/filter.model';
import { filterNilValue } from '@datorama/akita';
import { resolveDotNotationPath, setObjectValueAtPath } from '../../_core/utils/object.utils';
import { TableQuery } from './table.query';
import { getDeepLinkPath, getValueTypeMaskedValue, prepareForApi } from './table.utils';
import { StagedModificationQuery } from '../entities/staged-modification/staged-modification.query';
import { EntityPlurals } from '../entities/entities.utils';
import { getProgramClassificationStatusColor, Program } from '../entities/program/program.model';
import { BehaviorSubject, EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { ProgramSelect } from '../../../../../api/src/program/utils/query.utils';
import { getTacticGroupStatusColor } from '../entities/tactics-groups/tactics-group.model';
import { TacticGroup, TacticGroupStatus } from '../../../../../api/src/tactic-group/tactic-group.entity';
import { Tactic } from '../entities/tactic/tactic.model';
import { ProgramClassification, ProgramClassificationStatus } from '../../../../../api/src/program/program.entity';
import { ExportRange } from '../../../../../api/src/export/dtos/export.dto';
import { MeasurementAggregateType } from '../entities/measurement/measurement.model';
import { capitalize } from '../../_core/utils/string.utils';
import { CostService } from '../entities/cost/cost.service';
import * as CryptoJS from 'crypto-js';

export interface ExportRequestResponse {
	id: string;
	status: string;
	created: string;
}

interface CachedData {
	[endpoint: string]: {
		apiReadyParams: FilterParameters;
		results: Observable<any>;
	};
}

@Injectable({ providedIn: 'root' })
export class TableService {
	public rowCount = 0; // Used to iterate row numbers recursively through a function.
	public prepareForApi = prepareForApi;

	private cachedData: BehaviorSubject<CachedData> = new BehaviorSubject({});
	private requestSubjects: { [endpoint: string]: ReplaySubject<CachedData> } = {};
	private cachedDataReverseHashMap: Map<string, FilterParameters> = new Map();

	private selectedColumnsFromDialog: Partial<Record<AppSection, Column[]>> = {
		['planning']: [],
		['activation']: [],
		['media-plan']: [],
	};

	constructor(
		private tableStore: TableStore,
		private tableQuery: TableQuery,
		private globalQuery: GlobalQuery,
		private stagedModificationQuery: StagedModificationQuery,
		private costService: CostService,
		private http: HttpClient
	) {
		this.globalQuery.authenticatedSettings$.pipe(filterNilValue(), take(1)).subscribe((settings) => {
			// Add dynamic columns to table store
			this.setDynamicColumns(DynamicColumns, settings);
		});
	}

	getSelectedColumnsFromDialog(appSection: AppSection) {
		return this.selectedColumnsFromDialog[appSection] ?? [];
	}

	setSelectedColumnsFromDialog(columns: Column[], section: AppSection) {
		this.selectedColumnsFromDialog[section] = columns;
	}

	/**
	 * Insert a column into a collection in the table store
	 * @param columnCollectionId
	 * @param column
	 */
	insertColumnIntoCollection(columnCollectionId: string, column: Column) {
		const columnCollections = [...this.tableStore.getValue().tableSettings.columns];
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				columns: columnCollections.map((columnCollection) => {
					if (columnCollection.id === columnCollectionId) {
						return {
							...columnCollection,
							items: [...columnCollection.items, { ...column }],
						};
					}
					return columnCollection;
				}),
			},
		});
	}

	/**
	 * Clean cached data
	 * @description This is used to clear the cache when a user logs out.
	 */
	clearCachedData(): void {
		this.cachedData.next({});
	}

	/**
	 * Use the find endpoints to receive entity data from the API
	 * and format it into table collections.
	 * @param filters
	 * @param skipTableStoreUpdate - If true, the table store will not be updated.
	 */
	get(
		filters: FilterParameters,
		skipTableStoreUpdate?: boolean,
		options?: { section?: AppSection; overwriteEndpoint?: string }
	): Observable<TableCollection<any>> {
		this.tableStore.setLoading(true);

		const endpoint = options?.overwriteEndpoint != null ? options.overwriteEndpoint : filters.include?.value?.endpoint;
		const apiReadyParams = this.prepareForApi(filters, endpoint);

		if (!endpoint) return;

		if (!endpoint) return;

		let queryParams: HttpParams = new HttpParams();

		// Inject all the available brands for this user if we group by this
		if (apiReadyParams.groupBy === 'brands') {
			const brandIds = this.globalQuery.getValue().settings.brands.map((b) => b.id);
			if (!apiReadyParams.brandIds || apiReadyParams.brandIds?.length < 1) {
				apiReadyParams.brandIds = brandIds;
			}

			// Calculate the budget for the program by all brands
			apiReadyParams.extra = { ...apiReadyParams.extra, ignoreBrandIds: true };
		}

		// Inject all the available retailers for this user if we group by this
		if (apiReadyParams.groupBy === 'retailers') {
			const retailerIds = this.globalQuery.getValue().settings.retailers.map((r) => r.id);
			if (!apiReadyParams.retailerIds || apiReadyParams.retailerIds?.length < 1) {
				apiReadyParams.retailerIds = retailerIds;
			}
		}

		// Per page query param
		// console.log('Per Page', filters.perPage);
		if (filters.perPage) {
			queryParams = queryParams.set('perPage', filters.perPage.toString());
		}

		if (filters?.page) {
			queryParams = queryParams.set('page', filters.page.toString());
		}

		// Hack: Invoices don't like 'include' params
		if (endpoint === 'invoices') {
			apiReadyParams['include'] = undefined;
			// Force default columns for invoices
			apiReadyParams['columnSelect'] = undefined;
		}

		console.log('API Ready Params', apiReadyParams, filters);

		const updateTableStore = (response: TableCollection<any>) => {
			if (!skipTableStoreUpdate) {
				const rows = this.flattenIntoNestedRows(response, filters, undefined, undefined, undefined, undefined, options?.section);

				this.rowCount = 0;
				const rowIds = rows.map((r) => r.rowId);
				const expandedRowIds = this._getCustomExpandedRows(rows, filters);
				this.tableStore.update({
					rawData: response,
					rows,
					currentEndpoint: endpoint,
					tableSettings: {
						...this.tableStore.getValue().tableSettings,
						expandedRows: expandedRowIds,
						disabledRows: this._getCustomDisabledRows(rows, filters),
					},
				});
			}

			this.tableStore.setLoading(false);
		};

		// Hash the filters to use as a key
		const cleanedApiReadyParams = this._cleanFilters(apiReadyParams);
		const hashedFilters = this._hashCleanedApiReadyParams(cleanedApiReadyParams);

		// Store original filters for reverse lookup
		this.cachedDataReverseHashMap.set(hashedFilters, cleanedApiReadyParams);

		// Hash the filters to use as a key
		const cachedEndpointData = this.cachedData.value[endpoint];
		if (cachedEndpointData) {
			if (cachedEndpointData[hashedFilters]) {
				console.log('Using data for exact match');
				console.log('Activate: get()', cachedEndpointData[hashedFilters].results);
				return of(cachedEndpointData[hashedFilters].results).pipe(
					tap((response) => {
						updateTableStore(response);
					})
				);
			}

			// Check for subset matches
			for (const [key, entry] of Object.entries(cachedEndpointData)) {
				const cachedFilters = this.cachedDataReverseHashMap.get(key);
				if (cachedFilters && this._filtersIsSubset(cachedFilters, cleanedApiReadyParams)) {
					console.log('Using cached data for subset match', entry);
					return of((entry as { results: TableCollection<any> }).results);
				}
			}
		}

		// Check if a request is already in progress for the same endpoint and filters
		if (this.requestSubjects[endpoint]) {
			// Exact match check
			/* if (this.requestSubjects[endpoint][hashedFilters]) {
				console.log('Joining existing request for exact match');
				return this.requestSubjects[endpoint][hashedFilters].asObservable().pipe(map((cached: any) => cached.results));
			} */
			const subject = this.requestSubjects[endpoint][hashedFilters];
			const hasEmitted = Object.keys(subject?.getValue()?.results ?? {})?.length > 0;
			if (subject && hasEmitted) {
				console.log('Joining existing request for exact match');
				return this.requestSubjects[endpoint][hashedFilters].asObservable().pipe(map((cached: any) => cached.results));
			}

			// Subset match check
			for (const [key, subject] of Object.entries(this.requestSubjects[endpoint])) {
				const cachedFilters = this.cachedDataReverseHashMap.get(key);
				if (cachedFilters && this._filtersIsSubset(cachedFilters, cleanedApiReadyParams)) {
					console.log('Joining existing request for subset match');
					return subject.asObservable().pipe(map((cached: any) => cached.results));
				}
			}
		}
		// Create a ReplaySubject for ongoing requests
		if (!this.requestSubjects[endpoint]) {
			this.requestSubjects[endpoint] = {} as any;
		}
		/* 	const subject = new ReplaySubject<{
			apiReadyParams: FilterParameters;
			results: TableCollecti
			on<any>;
		}>(1); */
		const subject = new BehaviorSubject<{
			apiReadyParams: FilterParameters;
			results: TableCollection<any>;
		}>({
			apiReadyParams: {} as FilterParameters, // Empty or default FilterParameters
			results: {} as TableCollection<any>, // Empty or default TableCollection
		});
		this.requestSubjects[endpoint][hashedFilters] = subject;

		// End TODO
		// console.log('Hitting API', filters, queryParams);
		return this.http
			.post<TableCollection<any>>(
				`${environment.apiUrl}/organization/${environment.organizationId}/find/${endpoint}`,
				{
					...apiReadyParams,
				},
				{ params: queryParams }
			)
			.pipe(
				tap((response) => {
					console.log('Activate: get()', response);

					// Update cached data
					const currentCache = this.cachedData.value;
					if (!currentCache[endpoint]) {
						currentCache[endpoint] = {} as any;
					}
					currentCache[endpoint][hashedFilters] = {
						apiReadyParams: cleanedApiReadyParams,
						results: response,
					};
					this.cachedData.next(currentCache);

					// Update the table store
					updateTableStore(response);

					// Emit the response to the subject
					subject.next({
						apiReadyParams: cleanedApiReadyParams,
						results: response,
					});
					subject.complete();
					delete this.requestSubjects[endpoint][hashedFilters];
				})
			);
	}

	updateData(data: TableCollection<Entity>, filters: FilterParameters) {
		this.tableStore.update({
			rows: this.flattenIntoNestedRows(data, filters),
		});
	}

	updateRows(rows: TableRow<any>) {
		this.tableStore.update({
			rows,
		});
	}

	/**
	 * Get more items for the table.
	 * @param endpoint
	 * @param filters These are the filters for the actual get request.
	 * @param page
	 * @param perPage
	 * @param parentId
	 * @param tableFilters These are the top level filters for the table since the get more endpoint filters are usually limited.
	 * @param skipTableStoreUpdate If true, the table store will not be updated.
	 * @param section The section of the app that is requesting the data.
	 * @returns
	 */
	getMore(
		endpoint: string,
		filters: FilterParameters,
		page: number,
		perPage: number,
		parentId: string,
		tableFilters?: FilterParameters,
		skipTableStoreUpdate?: boolean,
		section?: AppSection
	) {
		// Pagination Params
		const params: HttpParams = new HttpParams().append('page', page.toString()).append('perPage', perPage.toString());

		// Replace strings for endpoint
		endpoint = endpoint
			.replace(':orgId', environment.organizationId)
			.replace(':planId', parentId)
			.replace(':programId', parentId)
			.replace(':tacticId', parentId);

		const baseEndpoint = filters?.include?.value?.endpoint;
		let apiReadyParams = { ...this.prepareForApi(filters, baseEndpoint) };

		// Omit other properties for these endpoints
		if (
			(endpoint.includes('program') && endpoint.includes('tactics')) ||
			(endpoint.includes('tactics') && endpoint.includes('invoices'))
		) {
			const { property, strategy } = apiReadyParams;
			apiReadyParams = { property, strategy };
		}

		// Cant have groupBy for any of these urls
		if (parentId) {
			// If we are grouping by brands, we need to make sure we remove the brandIds
			// So the budgets are correctly calculated.
			if (apiReadyParams.groupBy === 'brands') {
				apiReadyParams.extra = { ...apiReadyParams.extra, ignoreBrandIds: true };
			}

			apiReadyParams.groupBy = undefined;
			apiReadyParams.groups = undefined;
		} else {
			// if ((!apiReadyParams.retailers || apiReadyParams.retailers?.length < 1) && (!apiReadyParams.retailerIds || apiReadyParams.retailerIds?.length < 1)) {
			// 	console.warn('Adding in retailers', apiReadyParams);
			// 	apiReadyParams.retailerIds = this.globalQuery.getValue().settings.retailers.map(r => r.id);
			// }
			// TODO: Figure out when we can remove this due to API knowing that no retailers means ALL retailers / brands
			if (
				(!apiReadyParams.brands || apiReadyParams.brands.length < 1) &&
				(!apiReadyParams.brandIds || apiReadyParams.brandIds.length < 1)
			) {
				console.warn('Adding in brands', apiReadyParams);
				apiReadyParams.brandIds = this.globalQuery.getValue().settings.brands.map((b) => b.id);
			}
		}

		// Hack: Invoices don't like 'include' params
		// console.log('Hitting API', endpoint, apiReadyParams);
		if (endpoint.indexOf('invoices') > -1) {
			apiReadyParams['include'] = undefined;
		}

		return this.http
			.post<TableCollection<any>>(
				`${environment.apiUrl}${endpoint}`,
				{
					...apiReadyParams,
				},
				{
					params,
				}
			)
			.pipe(
				mergeMap((response) => {
					if (!skipTableStoreUpdate) {
						// Make sure our response has the right pagination details
						const collection = {
							...response,
							page,
							limit: perPage,
							totalPages: Math.ceil(response.totalResults / perPage),
						};

						// Insert our new rows where they need to go, and then update our rawData and rows
						const rawData = this.insertRowsIntoRawData(this.tableStore.getValue().rawData, parentId, collection, page);
						const rows = this.flattenIntoNestedRows(
							rawData,
							tableFilters || filters,
							undefined,
							undefined,
							undefined,
							undefined,
							section
						);

						// Should we expand the rows?
						// Yes, unless everything is collapsed.
						let expandedRows = this.tableStore.getValue().tableSettings.expandedRows.concat(rows.map((d) => d.rowId));
						if (this.tableStore.getValue().tableSettings.expandedRows.length < 1) {
							expandedRows = [];
						}

						this.rowCount = 0;
						this.tableStore.update({
							rawData,
							rows,
							tableSettings: {
								...this.tableStore.getValue().tableSettings,
								expandedRows: expandedRows,
							},
						});
					}
					return of(response);
				})
			);
	}

	public export(exportType: 'table' | 'calendar', params: FilterParameters, columns: Column[], exportRange?: ExportRange) {
		const endpoint = params.include?.value?.endpoint;
		const apiReadyParams = this.prepareForApi(params);

		// The API prefers 'list' instead of 'table' for the exportType
		let type: any = exportType;
		if (exportType === 'table') {
			type = 'list';
		}

		return this.http.post<ExportRequestResponse>(
			`${environment.apiUrl}/organization/${environment.organizationId}/export/${endpoint}`,
			{
				...apiReadyParams,
				columns,
				exportType: type,
				...(exportRange && Object.values(ExportRange).includes(exportRange) && { exportRange }),
			},
			{
				observe: 'body',
				responseType: 'json',
			}
		);
	}

	public checkExport(exportId: string) {
		return this.http.get<any>(`${environment.apiUrl}/organization/${environment.organizationId}/export/${exportId}`, {
			observe: 'body',
			responseType: 'json',
		});
	}

	public downloadExport(exportId: string) {
		window.open(`${environment.apiUrl}/organization/${environment.organizationId}/export/${exportId}/download`, '_self');
	}

	removeFromRows(id: Entity['id']) {
		const updatedRows = this.tableStore.getValue().rows.filter((row) => row.id !== id && row?.parentId !== id);
		this.tableStore.update({
			rows: updatedRows.length === 1 && updatedRows[0]?.children ? [] : updatedRows,
		});
	}

	insertRowsIntoRawData(data: TableCollection<Entity>, parentId: string, collection: TableCollection<Entity>, page: number) {
		let matched = false;

		// This should only happen for top level pagination...
		if (!parentId) {
			return {
				...data,
				items: [...data.items, ...collection.items],
				limit: collection.limit || data.limit,
				totalPages: collection.totalPages || data.totalPages,
				totalResults: collection.totalResults || data.totalResults,
				page,
			};
		}

		// Recursively dig through all our data to figure out where to add these rows
		return {
			...data,
			items: data.items.map((item) => {
				if (!matched && item.id === parentId) {
					matched = true;

					// Append the new rows to the children of the parent
					return {
						...item,
						children: {
							...item.children,
							items: [...item.children.items, ...collection.items],
							limit: collection.limit || item.children.limit,
							totalPages: collection.totalPages || item.children.totalPages,
							totalResults: collection.totalResults || item.children.totalResults,
							page,
						},
					};
				}

				// If we got this far and have children, run this recursively
				if (!matched && item.children) {
					return {
						...item,
						children: this.insertRowsIntoRawData(item.children, parentId, collection, page),
					};
				}

				// If we got this far, we probably already have a match
				return item;
			}),
		};
	}

	getActiveColumnEntities(item: Entity, columns: Column[]) {
		let row: Partial<TableItem<any>> = {};

		columns.forEach((column) => {
			let path = column.path;
			if (path === 'retailers') {
				path = 'retailer';
			}
			let value = resolveDotNotationPath(path, item);

			// The fallback helps some edge cases for columns
			if (value === undefined) {
				value = resolveDotNotationPath(column?.exportPath?.split('.')[0], item);
			}
			// console.log('TEST: Column:', key, column, resolveDotNotationPath(key, item), item);
			row = setObjectValueAtPath(
				row,
				path,
				getValueTypeMaskedValue(value, column.type, column, item, this.globalQuery.getValue().settings)
			);
		});

		return row;
	}

	/**
	 * Transform data to table rows
	 *
	 * @param data - The data to be formatted into rows
	 * @param filters - filter parameters to be used for formatting
	 * @param parent - parent row
	 * @param activeColumns - list of columns to be displayed
	 * @param level - current level of the row
	 * @param returnChildrenArray - return children as an array
	 * @param section - section of the app
	 * @param actionRow - action row settings
	 * @param cachedRows - cachedRows for storing row data
	 */
	flattenIntoNestedRows(
		data: TableCollection<Entity>,
		filters: FilterParameters,
		parent?: TableItem<Entity>,
		activeColumns?: Column[],
		level = 1,
		returnChildrenArray?: boolean,
		section?: AppSection,
		actionRow?: TableActionRowSettings,
		cachedRows?: Map<string, Partial<TableItem<Entity>>>
	): TableItem<Entity>[] {
		if (!cachedRows) {
			cachedRows = new Map<string, Partial<TableItem<Entity>>>();
		}

		let rows: TableItem<Entity>[] = [];
		// Detect if this layer is a group layer so that we can style it differently
		const isGroup = data?.type === filters?.groups?.entityName;
		const endpoint = filters.include?.value?.endpoint;
		const includes = filters.include?.value?.include || [];
		if (isGroup) {
			level = 0;
		}

		const columns = [
			...(activeColumns || this.tableQuery.getActiveColumns([endpoint, ...includes], true)),
			...this.tableQuery.getColumns(['Brand(s)'], [endpoint, ...includes]),
			...this.tableQuery.getBudgetColumns(),
			...(data?.type === 'Tactic' ? this.tableQuery.getTacticDetailsColumns() : []),
			...this.tableQuery.getStatusColumns(),
		];

		// Convert items into rows with less data
		data?.items.forEach((item) => {
			this.rowCount++;

			// Modify the item to be more table friendly.
			item = this.prepareRowForTable(data.type, item);

			// If we're in the calendar edit mode, we need to merge in any staged modifications
			// so that the edits are still reflected for the user.
			if (this.tableQuery.getCalendarEditMode()) {
				item = {
					...item,
					...this.stagedModificationQuery.getMergedModificationByEntityId(item.id),
				};
			}

			// Unique cache key for this row
			const cacheKey = item.id + item.name + item.type + item.deeplink;
			let activeColumnData: Object = {};
			if (cachedRows.has(cacheKey)) {
				activeColumnData = cachedRows.get(cacheKey)!;
			} else {
				activeColumnData = this.getActiveColumnEntities({ ...item, type: data.type }, columns);
				cachedRows.set(cacheKey, activeColumnData);
			}

			let row: TableItem<any> = {
				...activeColumnData,
				id: item.id,
				rowId: this.rowCount,
				brandCaches: item.brandCaches,
				parentRowId: parent?.rowId,
				type: data.type,
				parentId: parent?.id || item?.programId,
				deepLink: undefined,
				clonable: ['Program', 'Tactic', 'TacticGroup'].indexOf(data.type) > -1,
				removeable: ['Plan', 'Program', 'Tactic', 'Invoice', 'TacticGroup'].indexOf(data.type) > -1,
				approvable: ['TacticGroup'].indexOf(data.type) > -1,
				unapprovable: ['TacticGroup'].indexOf(data.type) > -1,
				level,
				children: item?.children ? (returnChildrenArray ? item.children : true) : undefined,
				...(item?.programId && { programId: item.programId }),
				...(data.type === 'Program' && { investments: item?.investments?.reduce((acc, investment) => acc + investment.amount, 0) }),

				// Need these for calendar at all times
				startRaw: item.start,
				endRaw: item.end,
			};

			// Relational dependencies, if they exist
			if (item.program) {
				row.program = {
					...row.program,
					...item.program,
				};
			}
			if (item.tactic) {
				row.tactic = {
					...row.tactic,
					...item.tactic,
				};
			}
			if (item.plan) {
				row.plan = {
					...row.plan,
					...item.plan,
				};
			}

			// Deep Link
			row.deepLink = getDeepLinkPath(row, data.type, parent, item, section);

			row = {
				...row,
				name: this._getCustomRowName(row, item, section),
			};

			if (data.type === 'TacticGroup') {
				row = {
					...row,
					approvable: item?.status?.id === TacticGroupStatus.Draft || !item?.status,
					unapprovable: item?.status?.id === TacticGroupStatus.Approved,
				};
			}

			rows.push(row);

			if (item.children) {
				// Recursive add
				rows = rows.concat(
					this.flattenIntoNestedRows(
						item.children,
						filters,
						{ ...row },
						activeColumns,
						level + 1,
						undefined,
						section,
						undefined,
						cachedRows
					)
				);

				// Pagination
				if ((item.children.page || 1) * item.children.limit < item.children.totalResults) {
					this.rowCount++;

					const pagination = {
						id: item.id + '/pagination',
						name: {
							value: `Load more ${
								EntityPlurals[item.children?.type.toLowerCase()] || item.children?.type.toLowerCase() + 's'
							}`,
						},
						type: 'pagination',
						rowId: this.rowCount,
						parentRowId: row?.rowId,
						parentId: item.id,
						parentType: data.type,
						level: level + 1,
						page: item.children.page || 1,
						totalResults: item.children.totalResults,
						totalPages: item.children.totalPages,
						limit: item.children.limit,
						listType: item.children.type,
						paginationEndpoint: item.children.paginationEndpoint,
					} as TableItem<any>;
					rows.push(pagination);
					// console.log('Pagination', pagination);
				}

				if (actionRow) {
					rows.push({
						id: item.id + '/action-row',
						name: { value: `${actionRow.name}` },
						type: 'action-row',
						rowId: this.rowCount + 1,
						parentRowId: row?.rowId,
						parentId: item.id,
						level: level + 1,
					} as TableItem<any>);
					this.rowCount++;
				}
			}
		});
		// Allow root level action if no action row is present before
		if (actionRow && !rows.some((row) => row.type === 'action-row') && rows.length) {
			rows.push({
				id: 'top/action-row',
				name: { value: `${actionRow.name}` },
				type: 'action-row',
				rowId: this.rowCount + 1,
				parentRowId: parent?.rowId,
				parentId: undefined,
				parentType: undefined,
				level,
			} as TableItem<any>);
			this.rowCount++;
		}

		// Pagination
		// If this entity is a group, or at least doesn't have a parent, and has more results that we're showing.
		if ((isGroup || (!isGroup && !parent)) && data?.totalResults > data?.items.length) {
			rows.push({
				id: 'top/pagination',
				name: { value: `Load more ${EntityPlurals[data.type.toLowerCase()] || data.type.toLowerCase() + 's'}` },
				type: 'pagination',
				rowId: this.rowCount + 1,
				parentRowId: parent?.rowId,
				parentId: undefined,
				parentType: undefined,
				level: 0,
				page: data.page || 1,
				totalResults: data.totalResults,
				totalPages: data.totalPages,
				limit: data.limit,
				listType: data.type,
				paginationEndpoint: data.paginationEndpoint,
			} as TableItem<any>);

			this.rowCount++;
		}

		return rows;
	}

	setScrollTop(scrollTop: boolean) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				initScrollTop: scrollTop,
			},
		});
	}

	updateRowWithEntity(newData: TableRow<any>, activeColumns?: Column[]) {
		let oldRow: TableRow<any>;
		let newRow: TableRow<any>;

		this.tableStore.update({
			rows: this.tableQuery.getValue().rows.map((row) => {
				if (row.id === newData.id) {
					oldRow = { ...row };
					const newItem = this.prepareRowForTable(oldRow.type, newData);

					newRow = {
						...row,
						...this.getActiveColumnEntities(newItem, activeColumns),
					};

					console.log('Update Row', oldRow, newRow, activeColumns);

					return newRow;
				}

				return row;
			}),
		});

		// Let's check if this row has a parent, and if if it does, let's see about updating any budgetCache values.
		// TODO: There are several improvements that should probably happen here.  One is to find every row that is a part of this entity
		// So that we're handling cases where we're grouped by brands and the same entity is in multiple rows.  The other is to handle
		// The 'difference' value that is calculated against a different budgetCache value.  Right now, the 'difference' number will not update.
		if (newRow?.parentId && newRow?.budgetCache) {
			Object.keys(newRow.budgetCache).forEach((key) => {
				// Ignore if we don't have rawValues for both the old and new row.
				if (!newRow.budgetCache[key]?.rawValue || !oldRow.budgetCache[key]?.rawValue) {
					return;
				}

				// Get the difference between the new value and the old value
				const difference = newRow.budgetCache[key]?.rawValue - oldRow.budgetCache[key]?.rawValue;

				// If the difference is 0, we don't need to do anything
				if (difference === 0) {
					return;
				}

				// Otherwise, we need to add or subtract from the parent.
				const parentRow = this.tableQuery.getValue().rows.find((r) => r.id === newRow.parentId);

				if (parentRow) {
					const newValue = getValueTypeMaskedValue(parentRow.budgetCache[key]?.rawValue + difference, 'currency');

					this.tableStore.update({
						rows: this.tableQuery.getValue().rows.map((r) => {
							if (r.id === parentRow.id) {
								console.log('Setting Parent Budget', key, newValue, r);
								return {
									...r,
									budgetCache: {
										...r.budgetCache,
										[key]: newValue,
									},
								};
							}
							return r;
						}),
					});
				}
			});
		}
	}

	updateRowValue(id: string, columnName: string, value: any, column: Column, row: TableRow<any>) {
		const updatedValue = getValueTypeMaskedValue(value, column.type, column, row, this.globalQuery.getValue().settings);

		this.tableStore.update({
			rows: this.tableQuery.getValue().rows.map((row) => {
				if (row.id === id) {
					return {
						...row,
						[columnName]: updatedValue,
					};
				}
				return row;
			}),
		});
	}

	setColumnWidths(width: number, name: string) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				columnWidths: [
					...this.tableStore.getValue().tableSettings.columnWidths.filter((x) => x.name !== name),
					{
						name,
						width,
					},
				],
			},
		});
	}

	setContractedRows(isContracted: boolean) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				contractedRows: isContracted,
			},
		});
	}

	setArrayColumnWidths(arr: any) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				columnWidths: [...arr],
			},
		});
	}

	removeColumnWidths() {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				columnWidths: [],
			},
		});
	}

	clearCurrentEndpoint() {
		this.tableStore.update({
			currentEndpoint: undefined,
		});
	}

	setCalendarDateRange(dateRange: CalendarSettings['calendarDateRange']) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				calendarDateRange: dateRange,
			},
		});
	}

	setCalendarEditMode(state: boolean) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				calendarEditMode: state,
			},
		});
	}

	setExportToImage(state: boolean) {
		// console.log('image state',state);
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				exportToImage: state,
			},
		});
	}

	setLoading(state: boolean) {
		this.tableStore.setLoading(state);
	}

	setLoadingOnRow(rowId: TableItem<any>['id']) {
		this.tableStore.update({
			rows: this.tableStore.getValue().rows.map((row) => {
				if (row.id === rowId) {
					return {
						...row,
						name: { value: 'Loading...' },
					};
				}

				return row;
			}),
		});
	}

	setColumns(columns: ColumnCollection[]) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				columns: columns.map((columnCollection) => ({
					...columnCollection,
					items: this.maskColumnLabels(columnCollection.items),
				})),
			},
		});
	}

	/**
	 * Loop through dynamic columns and insert settings options into the store
	 * @param dynamicColumns
	 * @param settings
	 */
	setDynamicColumns(dynamicColumns: DynamicColumn[], settings: GlobalSettings) {
		// Loop through each dynamic column
		dynamicColumns.forEach((dynamicColumn) => {
			settings[dynamicColumn.settingsKey]?.forEach((item) => {
				// Merge in item name and id data to make a legit column object
				let column1 = this.createBaseColumn(dynamicColumn, item);
				if (dynamicColumn.column.category === 'Measurement') {
					column1 = this.createMeasurementColumn(
						dynamicColumn,
						item,
						MeasurementAggregateType.Actual,
						'ACT',
						capitalize(MeasurementAggregateType.Actual)
					);
				}

				// Insert the column into the store
				this.insertColumnIntoCollection(dynamicColumn.collectionName, column1);
				// Add a benchmark column if it's a measurement column
				if (dynamicColumn.column.category === 'Measurement') {
					const column2 = this.createMeasurementColumn(
						dynamicColumn,
						item,
						MeasurementAggregateType.Benchmark,
						'BM',
						capitalize(MeasurementAggregateType.Benchmark)
					);
					// Insert the column into the store
					this.insertColumnIntoCollection(dynamicColumn.collectionName, column2);
				}
			});
		});
	}

	/**
	 * Creates a base column object by merging dynamic column properties with item data
	 * @param dynamicColumn - The dynamic column configuration
	 * @param item - The item data from settings
	 * @returns The base column object
	 */
	createBaseColumn(dynamicColumn: DynamicColumn, item: any): Column {
		return {
			...dynamicColumn.column,
			id: dynamicColumn.column.id.replace('$id', item.id),
			name: dynamicColumn.column.name.replace('$name', item.name),
			path: dynamicColumn.column.path.replace('$name', item.name).replace('$id', item.id),
			filter: {
				...dynamicColumn.column.filter,
				value: item.id,
			},
			extra: {
				...dynamicColumn.column.extra,
				mask: resolveDotNotationPath(dynamicColumn.maskTypePath, item) || undefined,
				dynamic: true, // Tag these columns in case we need to modify them
			},
		};
	}

	/**
	 * Creates a column for the measurement category with specific postfix and tooltip
	 * @param baseColumn - The base column object to extend
	 * @param item - The item data from settings
	 * @param type - The type of measurement ('actual' or 'benchmark')
	 * @param nameSuffix - The suffix to append to the column name
	 * @param tooltipText - The tooltip text to use for the column
	 * @returns The extended measurement column object
	 */
	createMeasurementColumn(
		dynamicColumn: DynamicColumn,
		item: any,
		type: MeasurementAggregateType,
		nameSuffix: string,
		tooltipText: string
	): Column {
		return {
			...dynamicColumn.column,
			id: dynamicColumn.column.id.replace('$id', item.id) + `-${type}`,
			name: dynamicColumn.column.name.replace('$name', item.name) + ` ${nameSuffix}`,
			path: dynamicColumn.column.path.replace('$name', item.name).replace('$id', item.id) + `-${type}`,
			filter: {
				...dynamicColumn.column.filter,
				value: item.id,
			},
			aggregate: {
				...dynamicColumn.column.aggregate,
				path: type === MeasurementAggregateType.Benchmark ? MeasurementAggregateType.Benchmark : 'value',
			},
			extra: {
				...dynamicColumn.column.extra,
				alternativeName: dynamicColumn.column.name.replace('$name', item.name),
				tooltipText,
				mask: resolveDotNotationPath(dynamicColumn.maskTypePath, item) || undefined,
				dynamic: true, // Tag these columns in case we need to modify them
			},
		};
	}

	toggleRow(row: TableRow<any>) {
		let expandedRows = [...this.tableStore.getValue().tableSettings.expandedRows];
		const foundIndex = expandedRows.findIndex((d) => d === row.rowId);

		if (foundIndex > -1) {
			expandedRows = this.removeFromArray(expandedRows, row.rowId);

			// contract all siblings
			this.getSiblingsOfRow(row.rowId).forEach((r) => {
				expandedRows = this.removeFromArray(expandedRows, r.rowId);

				this.getSiblingsOfRow(r.rowId).forEach((r2) => {
					expandedRows = this.removeFromArray(expandedRows, r2.rowId);

					// Three layers deep for now
					this.getSiblingsOfRow(r2.rowId).forEach((r3) => {
						expandedRows = this.removeFromArray(expandedRows, r3.rowId);
					});
				});
			});
		} else {
			expandedRows.push(row.rowId);
		}

		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				expandedRows,
			},
		});
	}

	prepareRowForTable(type: string, item: TableItem<any>) {
		switch (type?.toLowerCase()) {
			case 'plan':
				return {
					...item,
					status: {
						id: item.status,
						name: item.status,
						color: getPlanStatusColor(item.status),
					},
				};
			case 'tacticgroup':
				return {
					...item,
					status: {
						id: item.status,
						name: item.status,
						color: getTacticGroupStatusColor(item.status),
					},
				};
			case 'program':
				return {
					...item,
					status: {
						id: item.status,
						name: item.status,
						color: getPlanStatusColor(item.status),
					},
					classificationStatus: {
						id: item.classificationStatus,
						name: item.classificationStatus,
						color: getProgramClassificationStatusColor(item.classificationStatus),
					},
				};
			case 'invoice':
				return {
					...item,
					name: `Invoice #${item.number}`,
					budgetCache: {
						...item.budgetCache,
						spendActual: item.amount,
					},
				};

			default:
				return item;
		}
	}

	removeFromArray(arr: string[], item: string) {
		const foundIndex = arr.findIndex((d) => d === item);
		if (foundIndex > -1) {
			arr.splice(foundIndex, 1);
		}

		return arr;
	}

	getSiblingsOfRow(rowId: string) {
		return this.tableStore.getValue().rows.filter((r) => r.parentRowId === rowId);
	}

	expandAllRows() {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				expandedRows: this.tableStore.getValue().rows.map((d) => d.rowId),
			},
		});
	}

	clearAllRows() {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				expandedRows: [],
			},
		});
	}

	updateSection(section: string) {
		this.tableStore.update({
			tableSettings: {
				...this.tableStore.getValue().tableSettings,
				section,
			},
		});
	}

	clearRawData() {
		this.tableStore.update({
			rawData: undefined,
		});
	}

	/**
	 * Iterate through filters and mask any filters or options with a mask path property
	 * @param filters
	 * @returns
	 */
	maskColumnLabels(columns: Column[]): Column[] {
		return columns.map((column) => {
			const _column = {
				...column,
			};

			// Mask names for any column with a mask path
			if (column.extra?.maskPath) {
				_column.name =
					resolveDotNotationPath(column.extra.maskPath, this.globalQuery.getValue().settings?.settings?.entities) || column.name;
			}

			return _column;
		});
	}

	uniqBy(arr, predicate) {
		const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

		return [
			...arr
				.reduce((map, item) => {
					const key = item === null || item === undefined ? item : cb(item);

					map.has(key) || map.set(key, item);

					return map;
				}, new Map())
				.values(),
		];
	}

	private _getCustomExpandedRows(rows: TableItem<Entity>[], params: Partial<FilterParameters>) {
		// If we're loading media plan programs, we want to expand all rows that are in approved status
		if (params.classification === ProgramClassification.MediaPlan) {
			return rows
				.filter(
					(row) => !(row.type === 'Program' && row?.classificationStatus?.rawValue?.id !== ProgramClassificationStatus.Approved)
				)
				.map((d) => d.rowId);
		}
		// If we're loading tactic groups, we want to expand all rows that are in approved status
		else if (params?.groups?.id === 'tacticGroups') {
			const hasApprovedStatus = rows.some(
				(row) => row.type === 'TacticGroup' && row?.status?.rawValue?.id === TacticGroupStatus.Approved
			);
			if (hasApprovedStatus) {
				return rows
					.filter((row) => row.type === 'TacticGroup' && row?.status?.rawValue?.id === TacticGroupStatus.Approved)
					.map((d) => d.rowId);
			} else {
				return rows.map((d) => d.rowId);
			}
		}

		return rows.map((d) => d.rowId);
	}

	private _getCustomDisabledRows(rows: TableItem<Entity>[], params: Partial<FilterParameters>) {
		if (params.classification === ProgramClassification.MediaPlan) {
			const hasAtLeastOneApproved = rows.some((r) => r.classificationStatus?.rawValue?.id === ProgramClassificationStatus.Approved);

			if (!hasAtLeastOneApproved) {
				// No disabled rows if there are no approved programs
				return [];
			}

			const rowIds = rows
				.filter((r) => r.classificationStatus?.rawValue?.id !== ProgramClassificationStatus.Approved)
				.map((r) => r.rowId);
			const expandedRowIds = this._getCustomExpandedRows(rows, params);

			return this._getExcludedRowsIds(rowIds, expandedRowIds);
		}

		return [];
	}

	private _getExcludedRowsIds(rowIds: string[], expandedRowIds: string[]) {
		const exclusionSet = new Set(expandedRowIds);
		return rowIds.filter((element) => !exclusionSet.has(element));
	}

	private _getCustomRowName(row: TableRow<any>, entity: Entity, section: AppSection) {
		if (row.type === 'Tactic' && entity.tacticGroupId && entity?.tacticGroup?.status === TacticGroupStatus.Approved) {
			return {
				...row.name,
				value: `(Group) ${row.name.value}`,
				rawValue: `(Group) ${row.name.rawValue}`,
			};
		} else if (
			row.type === 'Program' &&
			entity.classification === ProgramClassification.MediaPlan &&
			[ProgramClassificationStatus.Draft, ProgramClassificationStatus.Approved].includes(row?.classificationStatus?.rawValue?.id)
		) {
			const approvedTacticGroup =
				entity?.children?.items.find((item) => item?.tacticGroup?.status === TacticGroupStatus.Approved)?.tacticGroup?.name ||
				(entity?.classificationStatus?.id === ProgramClassificationStatus.Approved ? entity : undefined);

			return {
				...row.name,
				tooltip:
					approvedTacticGroup && row?.classificationStatus?.rawValue?.id === ProgramClassificationStatus.Approved
						? undefined
						: 'Approve a scenario to see the Media Plans details',
				value: ` ${row.name.value}
				 ${
						section === 'media-plan'
							? `<br/>
								<span style="font-weight: 200;font-size: 12px">
				${approvedTacticGroup?.name ?? `${entity?.tacticsGroups ? entity?.tacticsGroups?.length : 0} Scenarios`}</span>`
							: ''
					}`,
				rawValue: {
					value: row.name.value,
					subValue:
						section === 'media-plan'
							? approvedTacticGroup?.name ?? `${entity?.tacticsGroups ? entity?.tacticsGroups?.length : 0} Scenarios`
							: '',
				},
			};
		} else {
			return row.name;
		}
	}

	private _cleanFilters(apiReadyParams: FilterParameters): FilterParameters {
		console.log('%c apiReadyParams', 'color: #bada55', apiReadyParams);
		const cleanedFilters = Object.keys(apiReadyParams).reduce((acc, key) => {
			const value = apiReadyParams[key];

			// Exclude undefined, null, empty string, empty array, or empty object
			if (
				value !== undefined &&
				value !== null &&
				!(Array.isArray(value) && value.length === 0) &&
				!(typeof value === 'string' && value.trim() === '') &&
				!(typeof value === 'object' && Object.keys(value).length === 0)
			) {
				acc[key] = value;
			}

			return acc;
		}, {} as FilterParameters);

		return cleanedFilters;
	}

	private _hashCleanedApiReadyParams(cleanedApiReadyParams: FilterParameters): string {
		const jsonString = JSON.stringify(cleanedApiReadyParams);
		return CryptoJS.MD5(jsonString).toString();
	}

	private _filtersIsSubset(obj: any, subset: any): boolean {
		console.log('%c obj', 'color: #bada55', obj, subset);

		// List of keys that should always cause the function to return false
		const exceptions = ['extra', 'groupBy', 'sort'];

		for (const key in subset) {
			if (exceptions.includes(key)) {
				console.error(`Key "${key}" is in the exceptions list. Subset comparison is not allowed.`);
				return false;
			}

			if (!(key in obj)) {
				console.error(`Key "${key}" not found in the main object.`);
				return false;
			}

			if (subset[key] instanceof Array) {
				const arraysMatch =
					Array.isArray(obj[key]) &&
					obj[key].length === subset[key].length &&
					[...obj[key]].sort().join(',') === [...subset[key]].sort().join(',');

				if (!arraysMatch) {
					console.error(`Array mismatch for key "${key}":`, {
						objValue: obj[key],
						subsetValue: subset[key],
					});
					return false;
				}
			} else if (subset[key] instanceof Object) {
				if (!this._filtersIsSubset(obj[key], subset[key])) {
					return false;
				}
			} else {
				if (obj[key] !== subset[key]) {
					console.error(`Primitive value mismatch for key "${key}":`, {
						objValue: obj[key],
						subsetValue: subset[key],
					});
					return false;
				}
			}
		}
		return true;
	}
}
